import React from 'react';

const PaysafecardWithdrawPage = () => {
    return (
        <div id='paysafecard-withdraw'>
            <div className='paysafecard-withdraw-content'>
                <h3>Can I withdraw with Paysafecard?</h3>
                <p>Australian player can no longer deposit via Paysafecard. There are indications that several online casinos in Australia offer Paysafecard as a withdrawal method. Please check with your gambling operator for more information.</p>
            </div>
        </div>
    );
}

export default PaysafecardWithdrawPage;