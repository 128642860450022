import React from 'react';
import { FaRegSmile, FaTh } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import FAQComponent from '../FAQComponent';

const FAQPage = () => {
    return (
        <div id='faq'>
            <div className='faq-content'>
                <div className='faq-left'>
                    <h2>FAQ(s)</h2>
                    <p>For more information about how you can use Paysafecard to play on your favorite online casino platform, please check the FAQ below.</p>
                </div>
                <div className='faq-right'>
                    <IconContext.Provider value={{ color: "white", className: "icon", size: "150px" }}>
                        <FAQComponent className='icon' number='125000' text='Satisfied customers'>
                            <FaRegSmile />
                        </FAQComponent>
                        <FAQComponent className='icon' number='6300' text='Casino platforms'>
                            <FaTh />
                        </FAQComponent>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
}

export default FAQPage;
