import React from 'react';

const FAQComponent = ({ children, number, text }) => {
    return (
        <div className="faq-component">
            { children }
            <strong><p className='faq-number'>{ number } +</p></strong>
            <p className='faq-text'>{ text }</p>
        </div>
    );
}

export default FAQComponent;