import React from 'react';

const Footer = () => {
    return (
        <div id='footer'>
            <p>© getPaysafecard</p>
        </div>
    );
}

export default Footer;