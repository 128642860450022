import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

export default function Navigation() {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    right: `${isHamburgerClicked ? "0px" : "10000px"}`,
  }

  return (
    <div className="navigation">
      <div className="hamburger-icon" onClick={handleNavBtnClick}>
        <div className="hamburger-icon-line"></div>
        <div className="hamburger-icon-line"></div>
        <div className="hamburger-icon-line"></div>
      </div>

      <div className="navigation-main" style={mobileNavBarStyle}>
        <div className="x" onClick={handleNavBtnClick}>
          x
        </div>
        <ul style={mobileNavBarStyle}>
          <li>
            <Link to="#australian-casinos">
              Australian Online Casinos That Accept Paysafe Card
            </Link>
          </li>
          <li>
            <Link to="#neosurf">
              Neosurf - Alternative To Paysafecard Option
            </Link>
          </li>
          <li>
            <Link to="#faq">FAQ(s)</Link>
          </li>
        </ul>
        <p className="navbar-footer">© 2022 getpaysafecard.com</p>
      </div>
    </div>
  )
}
