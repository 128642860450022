import React from "react"
import {
  Home,
  AustralianCasinos,
  Neosurf,
  FAQ,
  QuickTips,
  PaysafecardWithdraw,
  FAQPart2,
  Footer,
} from "../components/pages/index"
import "../style/app.css"
import Helmet from "react-helmet"
import Favicon from "../img/favicon.png"

export default () => (
  <>
    <Helmet>
      <title>Paysafe Casinos Australia – Accepting Paysafecard 2022</title>
      <meta
        name="title"
        content="Paysafe Casinos Australia – Accepting Paysafecard 2022"
      />
      <meta
        name="description"
        content="Find out all the information you need about Paysafe Casinos Australia in 2022. Visit getpaysafecard.com for Paysafecard Online Casinos."
      />
      <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Helmet>
    <Home />
    <AustralianCasinos />
    <Neosurf />
    <FAQ />
    <QuickTips />
    <PaysafecardWithdraw />
    <FAQPart2 />
    <Footer />
  </>
)
