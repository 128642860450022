import React from 'react';
import QuickTipsImg from '../../img/Frequently-asked-questions-about-Paysafecard.jpg';

const QuickTipsPage = () => {
    return (
        <div id='quick-tips'>
            <div className="quick-tips-content">
                <img className='quick-tips-img' src={ QuickTipsImg } alt='Frequently Asked Questions' />
                <h3>Is it really secure to use Paysafecard for online casino deposits?</h3>
                <p>Yes, it is, but this doesn’t mean it’s bullet-proof. Indeed, you won’t divulge any sensitive information that exposes you, yet, if you use Paysafecard on a compromised device, a hacker could steal your PIN. If you keep using that compromised PIN and top up with fresh funds, the hacker might use it first. Also, be on the lookout for any suspicious phone calls or installed software that ask you for a Paysafecard PIN. This is how usually hackers scam the unaware.</p>
                <h3>What are the fees for using Paysafecard?</h3>
                <p>If you are using a PIN for more than one year, there is a 4 AUD maintenance fee deducted from your PIN balance, 12 months after purchase. If you are using another currency, you will also be subject to a conversion fee. Other than that, using Paysafecard is pretty much free.</p>
                <h3>Are there any limits for buying Paysafecard eVouchers?</h3>
                <p>There are limitations you have to take into consideration if you want to use Paysafecard for online casino deposits. The maximum combined transactions for a single PIN is 200 AUD. If you use My Paysafecard service, you cannot have more than 1,000 AUD from your combined managed PINs. Moreover, the annual limitation for your used PINs related to My Paysafecard service is set a a maximum of 6,000 AUD.</p>
            </div>
        </div>
    );
}

export default QuickTipsPage;
