import React from "react"
import PaysafeImg from "../../img/Paysafe-Card-Popular-Payment-Method.jpg"
import Navigation from "../Navigation"

const HomePage = () => {
  return (
    <div id="home" className="home">
      <Navigation />
      <div className="home-top">
        <h1 className="title">
          <span className="title-red">Paysafe Casinos Australia - </span>
          <br />
          <span className="title-white">
            Accepting Paysafecard <br />
            2022
          </span>
        </h1>
        <button className="home-button">Join NOW!</button>
      </div>
      <div className="home-bottom">
        <p className="home-centered-text">
          Are you tired of waiting around for your wire transfer to reach your
          online casino user account? Or maybe you are a cash person and you
          don’t have the desired amount in the bank to deposit with your VISA or
          MasterCard? Or simply you don’t want to use your personal credit card
          to fund your online casino balance. Whichever the case may be, you’re
          in luck. There is another, much more convenient option.{" "}
          <em>Paysafe</em> is the name! And it doesn’t involve a run on the
          bank.
        </p>
        <div className="image-and-text">
          <img
            className="image-and-text-image"
            src={PaysafeImg}
            alt="Paysafe Card – Popular Payment Method"
          />
          <div className="image-and-text-text">
            <p>
              All you have to do is go to the nearest convenience store,
              supermarket, or even your local newsstand, ask for a{" "}
              <strong>Paysafecard voucher</strong>, pay for it, and use it to
              deposit on your favorite Paysafe Casino AUS website. If you’re on
              your way home, from work, you can even buy a voucher from a gas
              station, straight up.
            </p>
            <p>
              What is this Paysafe Card, you ask? It is a neat e-payment ‘trick’
              that can potentially save you a lot of time. Created in the early
              2000s in Europe, it acts like your casual prepaid SIM card.
              Whenever your SIM card has run out of money or has expired, you go
              to a local store to reactivate it. As an option, you can buy a
              scratchcard that has a specific value, scratch it, enter the
              secret code, and voila, you can now resume your conversation or
              browsing.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
