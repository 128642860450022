import React from 'react';
import NeosurfImg from '../../img/Alternative-to-Paysafecard-Neosurf-vouchers.jpg'

const NeosurfPage = () => {
    return (
        <div id="neosurf">
            <div className='neosurf-content-all'>
                <div className="neosurf-text-and-image">
                    <h2>Neosurf - Alternative To Paysafecard Option</h2>
                    <img src={ NeosurfImg } alt='Alternative to Paysafecard – Neosurf vouchers' />
                </div>
                <div className="neosurf-content">
                    <p><strong>Neosurf is a voucher-based payment service</strong> that is easy to use and secure, as you don’t share sensitive information with anyone.</p>
                    <p>Much like Paysafecard, you can get a Neosurf voucher in convenience stores, supermarkets, gas stations, newsstands, tobacco kiosks, all over Australia. You can buy a classic voucher worth 15 AUD, 30 AUD, 50 AUD, or 100 AUD. You also have the option for a minor Neosurf worth 10 or 20 AUD. All vouchers have a unique 10-character code which you can use to make a deposit and start playing your favorite casino games online. If you have leftovers, there is the possibility to transfer them to another Neosurf voucher, up to 250 AUD. As always, your real-life identity will remain hidden, while your sensitive financial data will be safe.</p>
                    <p>Since Paysafecard has left quite a gap in the online casino environment around Australia, Neosurf may successfully fill it and help Australian players gamble from the <strong>safety of their own home, without divulging important information online.</strong></p>
                </div>
            </div>
        </div>
    );
}

export default NeosurfPage;