import React from "react"
import OnlineCasinosImg from "../../img/Online-Casinos-Australia-that-accept-Paysafe-Card-in-2019.jpg"

const AustralianCasinosPage = () => {
  return (
    <div id="australian-casinos">
      <div className="image-and-text">
        <div className="image-and-text-text">
          <h2>Australian Online Casinos That Accept Paysafe Card</h2>
          <p>
            Paysafecard basically works the same way. You go to a local store,
            supermarket, gas station, or even pharmacy and ask for a 10, 20, 50
            or 100 AUD Paysafe card. After you pay for it, the vendor will give
            you a printout that has several details on it, the most important
            one being a <strong>16-digit PIN</strong>. With that PIN, you can
            make a lightning-fast deposit on your favorite online{" "}
            <a href="https://www.au-slots.com/" target="_blank">
              casino platform.
            </a>{" "}
            No other information required.
          </p>
        </div>
        <div className="image-and-text-image">
          <img
            src={OnlineCasinosImg}
            alt="Online Casinos Australia that accept Paysafe Card in 2022"
          />
        </div>
      </div>
      <div className="evouchers">
        <div className="evouchers-content">
          <p>
            In the first couple of years, the company used scratchcards, yet
            after 2002, they switched to set printouts called{" "}
            <strong>‘eVouchers’</strong>. If you want more convenience, you can
            download the Paysafecard app for even faster payments and a quick
            balance lookup. With the app, you have the possibility to manage
            multiple PINs and - why not - reuse them whenever you feel the need
            for an adrenaline rush-based fun at your favorite Online Casino.
          </p>
          <p>
            You can find Paysafecard retailers all over Australia, from City
            Convenience Store and News Xpress to Tele Choice, Harvey Norman,
            United Petroleum, or WH Smith.
          </p>
          <p>
            What about Online Casinos, you ask? It shouldn’t be too hard to find
            a suitable online casino that accepts Paysafecard as a deposit
            option in 2022 for Australian players… oh, wait. Scratch that,
            actually.
          </p>
          <p>
            It wasn’t that hard in the past. Paysafecard doesn’t look like a
            viable option anymore, for the Australian players, at least. Don’t
            look disappointed though. We do have a more intriguing payment
            alternative that is definitely up your alley.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AustralianCasinosPage
